import { error, success } from "../../shared/Alert";
import {
  CONTACT_NUMBER,
  DOB,
  EMAIL,
  FIRST_NAME,
  KYC_PENDING,
  LAST_NAME,
  NAME_CHANGE_WARNING_HEADING,
  NAME_CHANGE_WARNING_SUBHEADING,
  NOT_VERIFIED,
  PENDING,
  PROFILE_UPDATE_CONFIRM_HEADING,
  PROFILE_UPDATE_CONFIRM_SUBHEADING,
  REJECTED,
  VERIFIED,
} from "./constants";
import { ExecuteHttpRequest } from "../../../ExecuteHttpRequest";
import { parseUrl } from "../../../ExecuteHttpRequest/helper";
import { methods, UserUrls } from "../../../utils";
import { HTTP_STATUS_CODE, BADGE_VARIANT } from "../../../utils/Constants";

export const getUpdatesValues = (data) => {
  const { firstName, lastName, contactNumber, email, dob } = data;

  return { firstName, lastName, contactNumber, email, dob };
};

export const updateUserProfileHandler = async (
  formData,
  setIsSubmitting,
  reset,
  handeUpdateUserProfile
) => {
  setIsSubmitting(true);

  const resp = await ExecuteHttpRequest(
    methods.PUT,
    UserUrls.userProfileUrl,
    formData
  );

  if (resp.status === HTTP_STATUS_CODE.OK && resp.data) {
    success(resp.message);
    reset(getUpdatesValues(resp.data));
    handeUpdateUserProfile(resp.data);
  } else {
    error(resp.message);
  }

  setIsSubmitting(false);
};

export const defaultUserProfileValues = {
  loading: false,
  data: {},
};

export const prefillUserValues = (userProfile, setValue) => {
  if (userProfile?.data?.firstName)
    setValue(FIRST_NAME, userProfile?.data?.firstName);

  if (userProfile?.data?.lastName)
    setValue(LAST_NAME, userProfile?.data?.lastName);

  if (userProfile?.data?.email) setValue(EMAIL, userProfile?.data?.email);

  if (userProfile?.data?.contactNumber)
    setValue(CONTACT_NUMBER, userProfile?.data?.contactNumber);

  if (userProfile?.data?.dob) setValue(DOB, userProfile?.data?.dob);
};
export const getSumSubToken = async (id, setSumSubToken, setLoading) => {
  let url = parseUrl(UserUrls.getSumSubTokenUrl, {
    id: id,
  });
  const resp = await ExecuteHttpRequest(methods.GET, url);

  if (resp.status === HTTP_STATUS_CODE.OK && resp.user && resp.user.token) {
    setSumSubToken(resp.user.token);
    setLoading(false);
  } else {
    setLoading(false);
  }
};

export const updateKYCLevel = async (
  id,
  level,
  handleRefreshUserProfile,
  setIsSumSubVerificationOpen
) => {
  const resp = await ExecuteHttpRequest(
    methods.PUT,
    UserUrls.updateKycLevelUrl,
    { id, level }
  );
  if (resp.status === HTTP_STATUS_CODE.OK) {
    handleRefreshUserProfile();
    setIsSumSubVerificationOpen(false);
  }
};

export const changeUserEmailHandler = async (
  formData,
  onCloseModal,
  emailChangeToken
) => {
  const resp = await ExecuteHttpRequest(
    methods.PUT,
    UserUrls.changeUserEmailUrl,
    {
      ...formData,
      emailToken: emailChangeToken.token,
    }
  );

  if (resp.status === HTTP_STATUS_CODE.OK) {
    success(resp.message);
    onCloseModal();
  } else {
    error(resp.message);
  }
};

export const defaultEmailValue = {
  status: PENDING,
};

export const changeUserPasswordHandler = async (formData, onCloseModal) => {
  const resp = await ExecuteHttpRequest(
    methods.PUT,
    UserUrls.changeUserPasswordUrl,
    formData
  );

  if (resp.status === HTTP_STATUS_CODE.OK) {
    success(resp.message);
    onCloseModal();
  } else {
    error(resp.message);
  }
};

export const defaultTwoFa = {
  loading: false,
  enabled: false,
  dataUrl: "",
  secret: "",
};

export const getResetTwoFA = async (setTwoFa) => {
  setTwoFa((prev) => {
    return { ...prev, loading: true };
  });

  const resp = await ExecuteHttpRequest(
    methods.GET,
    UserUrls.userResetTwoFaUrl
  );

  if (resp.status === HTTP_STATUS_CODE.OK) {
    const { qrCodeUrl, twoFaSecret } = resp.data;
    setTwoFa((prev) => {
      return {
        ...prev,
        enabled: false,
        loading: false,
        dataUrl: qrCodeUrl,
        secret: twoFaSecret,
      };
    });
  } else {
    setTwoFa((prev) => {
      return { ...prev, loading: false };
    });
    error(resp.message);
  }
};

export const verifyResetTwoFA = async (data, onCloseModal) => {
  const resp = await ExecuteHttpRequest(
    methods.POST,
    UserUrls.verifyResetTwoFaUrl,
    data
  );

  if (resp.status === HTTP_STATUS_CODE.OK) {
    success(resp.message);
    onCloseModal();
  } else {
    error(resp.message);
  }
};

export const defaultIsOpenValues = {
  passwordUpdate: false,
  emailUpdate: false,
  updateDetail: false,
  verifyPass: false,
};

export const defaultIs2FAOpenValues = {
  disableModal: false,
  resetModal: false,
};

export const verifyCurrentPasswordHandler = async (
  formData,
  onCloseModal,
  setIsOpen,
  setEmailChangeToken
) => {
  const resp = await ExecuteHttpRequest(
    methods.POST,
    UserUrls.verifyCurrentPasswordUrl,
    formData
  );

  if (
    resp.status === HTTP_STATUS_CODE.OK &&
    resp.data &&
    resp.data.emailToken
  ) {
    setEmailChangeToken((prev) => ({ ...prev, token: resp.data.emailToken }));

    onCloseModal();

    setIsOpen((prev) => ({
      ...prev,
      verifyPass: false,
      emailUpdate: true,
    }));
  } else {
    error(resp.message);
  }
};

export const defaultEmailChangeValues = {
  token: "",
};

export const cancelUpdateHandler = (userProfile) => {
  const { firstName, lastName, contactNumber, email, dob } = userProfile?.data;

  return { firstName, lastName, contactNumber, email, dob };
};

export const getKYCStatus = (level) => {
  level = parseInt(level);
  const getKYCInfo = (level) => {
    switch (level) {
      case 0:
        return { badge: BADGE_VARIANT.DANGER, status: REJECTED };
      case 1:
        return { badge: BADGE_VARIANT.DANGER, status: NOT_VERIFIED };
      case 2:
        return { badge: BADGE_VARIANT.WARNING, status: KYC_PENDING };
      case 3:
        return { badge: BADGE_VARIANT.PRIMARY, status: VERIFIED };
      default:
        return "";
    }
  };

  const KYCInfo = getKYCInfo(level);

  return (
    <p className={`badge badge-rounded mx-2 ${KYCInfo.badge}`}>
      {KYCInfo.status}
    </p>
  );
};

export const getUpdateAlertText = (data, userProfile) => {
  const isNameChangedHandler = (data) => {
    return (
      data?.firstName !== userProfile?.data?.firstName ||
      data?.lastName !== userProfile?.data?.lastName
    );
  };

  const isNameChanged = isNameChangedHandler(data);

  return {
    heading:
      isNameChanged &&
      userProfile.data.level !== 1 &&
      userProfile.data.level !== 2
        ? NAME_CHANGE_WARNING_HEADING
        : PROFILE_UPDATE_CONFIRM_HEADING,
    subHeading:
      isNameChanged &&
      userProfile.data.level !== 1 &&
      userProfile.data.level !== 2
        ? NAME_CHANGE_WARNING_SUBHEADING
        : PROFILE_UPDATE_CONFIRM_SUBHEADING,
  };
};
