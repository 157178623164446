import React, { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";

const ThemeToggleButton = () => {
  const { theme, handleChangeTheme } = useContext(ThemeContext);
  return (
    <div className={`theme-toggle-button form-check`}>
      <button
        role="switch"
        aria-checked={theme === "light"}
        id="nightDay"
        className="toggle-switch night-day table-toggle"
        checked={theme === "light"}
        onClick={(e) => {
          console.log("e e.target", e, e.target);
          handleChangeTheme(theme === "light" ? "dark" : "light");
        }}
      >
        <span aria-hidden="true"></span>
        <span class="knob"></span>
        <span aria-hidden="true">
          <span class="stars-container">
            <span class="star"></span>
            <span class="star"></span>
            <span class="star"></span>
            <span class="star"></span>
            <span class="star"></span>
            <span class="star"></span>
            <span class="star"></span>
          </span>
        </span>
      </button>
    </div>
  );
};

export default ThemeToggleButton;
